import { forwardRef, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import { closeSnackbar, CustomContentProps } from 'notistack';

const CustomSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
	({ id, variant, message, iconVariant, hideIconVariant, action }, forwardedRef) => {
		const onClose = useCallback(() => {
			closeSnackbar(id);
		}, [id]);

		return (
			<Alert
				ref={forwardedRef}
				action={typeof action === 'function' ? action(id) : action}
				onClose={onClose}
				severity={variant}
				icon={!hideIconVariant}
				iconMapping={iconVariant}
				variant="filled"
			>
				{message}
			</Alert>
		);
	}
);

export default CustomSnackbar;

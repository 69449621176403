import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import split from 'lodash-es/split';
import sum from 'lodash-es/sum';
import take from 'lodash-es/take';
import upperCase from 'lodash-es/upperCase';

import { hasFeatureAccess } from '../admin/helpers/AccessHelper';
import { ConnectionTypes } from '../settings/constants/ConnectionConstants';
import { filterConnectionList } from '../settings/Helper';

import { ListImportSource } from './components/tasks/discovery/Constants';
import { ApplicationTypes, TaskTypes } from './constants/TaskConstants';

export const connectionTypesByTask = (user, taskType) => {
	switch (taskType) {
		case TaskTypes.DEDUPLICATION:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_DEDUPLICATION`], null)
			);
		case TaskTypes.TOP_OFF:
		case TaskTypes.UE_TASK:
			return filterConnectionList([ConnectionTypes.SALESFORCE], user);
		case TaskTypes.ENRICHMENT:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.PARDOT,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
						ConnectionTypes.SNOWFLAKE,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_ENRICHMENT`], null)
			);
		case TaskTypes.NORMALIZATION:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.PARDOT,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_NORMALIZATION`], null)
			);
		case TaskTypes.MASS_DELETE:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.PARDOT,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_MASS_DELETE`], null)
			);
		case TaskTypes.MASS_UPDATE:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.PARDOT,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_MASS_UPDATE`], null)
			);
		case TaskTypes.LIST_IMPORT:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => hasFeatureAccess(user, [`${upperCase(connectionType)}_LIST_IMPORT`], null)
			);
		case TaskTypes.WEB_SUBMISSION:
			return filter(
				filterConnectionList(
					[
						ConnectionTypes.SALESFORCE,
						ConnectionTypes.MARKETO,
						ConnectionTypes.PARDOT,
						ConnectionTypes.ELOQUA,
						ConnectionTypes.HUBSPOT,
						ConnectionTypes.DYNAMICS,
					],
					user
				),
				connectionType => {
					if (
						connectionType === ConnectionTypes.SALESFORCE &&
						(hasFeatureAccess(user, ['W2L_MARKETO'], null) ||
							hasFeatureAccess(user, ['SF_ONLY_PREVENTION'], null))
					) {
						return true;
					}

					if (connectionType === ConnectionTypes.MARKETO && hasFeatureAccess(user, ['W2L_MARKETO'], null)) {
						return true;
					}

					return hasFeatureAccess(user, [`${upperCase(connectionType)}_W2L`], null);
				}
			);
		case TaskTypes.DATA_QUALITY_SCORE:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.MARKETO,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			);
		default:
			return [];
	}
};

export const getApplicationType = task => {
	if (task.application !== ApplicationTypes.WEBHOOK) {
		return 'Form Submission';
	}

	if (
		task.connection.type === ConnectionTypes.MARKETO ||
		(task.connection.type === ConnectionTypes.SALESFORCE &&
			task.secondary_connection &&
			task.secondary_connection.connection_type === ConnectionTypes.MARKETO)
	) {
		return 'Marketo Webhook';
	}

	if (task.connection.type === ConnectionTypes.SALESFORCE && !task.secondary_connection) {
		return 'Salesforce Trigger';
	}

	if (
		task.connection.type === ConnectionTypes.ELOQUA &&
		task.secondary_connection &&
		task.secondary_connection.connection_type === ConnectionTypes.SALESFORCE
	) {
		return 'Eloqua Canvas with Salesforce';
	}

	if (task.connection.type === ConnectionTypes.ELOQUA && !task.secondary_connection) {
		return 'Eloqua Canvas';
	}

	if (task.connection.type === ConnectionTypes.HUBSPOT && !task.form_submission) {
		return 'HubSpot Trigger';
	}

	if (task.connection.type === ConnectionTypes.DYNAMICS && !task.form_submission) {
		return 'Dynamics Webhook';
	}

	return 'Form Submission';
};

export const getListSource = task => {
	if (task.source_type === 'form' && task.storage) {
		return 'Amazon S3';
	}
	const source = find(ListImportSource, ['value', task.source_type]);
	return source ? source.label : 'List';
};

export const getCredits = record =>
	pickBy({ ...((record || {}).enrichment_credits_used || {}) }, (_v, k) => k !== 'NORMALIZATION');

export const getTotalCredits = record => sum(map(getCredits(record)));

export const getChildObjects = task => map(task.child_objects, childObject => split(childObject, '::')[0]);

export const getRequestLogOperationObject = log => {
	if (log.operation_object) {
		return log.operation_object;
	}

	if (log.object) {
		return log.object;
	}

	if (log.meta && log.meta.operation_object) {
		return log.meta.operation_object;
	}

	const HASH = {
		'00Q': 'Lead',
		'001': 'Account',
		'003': 'Contact',
	};

	return log.request && log.request.masterRecord ? HASH[take(log.request.masterRecord, 3)] || '' : '';
};

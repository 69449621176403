import amplitude from 'amplitude-js';

import { environment } from '../../../environments/environment';

const amplitudeProjectName = 'RingLead';
const platformName = environment.serverName !== 'release' ? 'RingLead staging' : 'RingLead';

export const initAmplitude = () => {
	amplitude
		.getInstance()
		.init(
			environment.serverName !== 'release'
				? '32975cb7e808264c73bea49bad1603ef'
				: '77cd2a146cc77c4445a21e5a6d5917a2'
		);
};

export const amplitudeLogEvent = (eventType: string, eventProperties: Record<string, unknown>) => {
	amplitude.getInstance().logEvent(eventType, {
		PDM_PLATFORM: platformName,
		PDM_APPLICATION: 'Web',
		PDM_APPLICATION_ATTRIBUTE: null,
		PDM_PRODUCT_CATEGORY: amplitudeProjectName,
		PDM_PRODUCT_TYPE: amplitudeProjectName,
		...eventProperties,
	});
};

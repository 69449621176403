import { createSlice } from '@reduxjs/toolkit';
import { amplitudeLogEvent } from 'app/integrations/amplitude/amplitude';

type NavbarState = {
	open: boolean;
	navbarState: Record<string, boolean>;
};

const navbarSlice = createSlice({
	name: 'navbar',
	initialState: {
		open: true,
		navbarState: JSON.parse(localStorage.getItem('navbarState') ?? '{}'),
	} as NavbarState,
	reducers: {
		toggleOpen: state => {
			amplitudeLogEvent('SidePanelColapsedExpanded', {
				sidePanelAction: state.open ? 'Collapsed' : 'Expanded',
				source: window.amplitudeSource,
				activity_group: 'Navigation',
			});
			state.open = !state.open;
		},
		toggleNavItemOpen: (state, action) => {
			if (state.navbarState[action.payload]) {
				delete state.navbarState[action.payload];
			} else {
				state.navbarState[action.payload] = true;
			}
			localStorage.setItem('navbarState', JSON.stringify(state.navbarState));
		},
	},
});

export const { toggleOpen, toggleNavItemOpen } = navbarSlice.actions;

export default navbarSlice.reducer;

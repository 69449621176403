export const environment = {
	production: true,
	apiBaseUrl: '/api/frontend/',
	sentryDsn: 'https://ee90d4211137422788a6044846fbcfed@o84462.ingest.sentry.io/194364',
	launchDarklyClientSideID: '62d9b0e3455a6e10e6f20849',
	pusherKey: '8148be10a31560f66c34',
	pusherCluster: 'us3',
	serverName: 'release',
	schedulerAppDomain: 'https://scheduler.ringlead.com',
};

import includes from 'lodash-es/includes';
import { LOCATION_CHANGE } from 'redux-first-history';

import { TaskConstants } from '../Constants';

const initialState = {
	fetchingMappedObjects: false,
	mappedObjects: [],
	archiveObjects: [],
	fetchingFileFields: false,
	fileFields: [],
	fetchingFilePreview: false,
	filePreviewRows: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (includes(action.payload.location.pathname, 'resolution')) {
				return state;
			}

			return initialState;

		case TaskConstants.MAPPED_OBJECTS_GET:
			return { ...state, fetchingMappedObjects: true };

		case TaskConstants.MAPPED_OBJECTS_GET_SUCCESS:
			return {
				...state,
				mappedObjects: action.data || [{ name: 'Lead', label: 'Lead' }],
				archiveObjects: action.data,
				fetchingMappedObjects: false,
			};

		case TaskConstants.MAPPED_OBJECTS_GET_FAIL:
			return { ...state, mappedObjects: [], archiveObjects: [], fetchingMappedObjects: false };

		case TaskConstants.FILE_FIELDS_GET:
			return { ...state, fetchingFileFields: true };

		case TaskConstants.FILE_FIELDS_GET_SUCCESS:
			return { ...state, fileFields: action.data, fetchingFileFields: false };

		case TaskConstants.FILE_FIELDS_GET_FAIL:
			return { ...state, fileFields: [], fetchingFileFields: false };

		case TaskConstants.FILE_PREVIEW_GET:
			return { ...state, fetchingFilePreview: true };

		case TaskConstants.FILE_PREVIEW_GET_SUCCESS:
			return { ...state, filePreviewRows: action.data, fetchingFilePreview: false };

		case TaskConstants.FILE_PREVIEW_GET_FAIL:
			return { ...state, filePreviewRows: [], fetchingFilePreview: false };

		default:
			return state;
	}
};
